/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import Page404 from "@/images/lp/home/404.svg"

import { 
  contentPage,
}  from '../assets/styles/Other.styles'

const SucessoContent = () => {
  return (
    <Fragment>
      <section css={contentPage}>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="content-sucess">
                <h2>Obrigado pelo seu interesse</h2>
                <p>Nosso time comercial entrará em contato com você</p>
                <img src={Page404} alt='/' />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default SucessoContent;
